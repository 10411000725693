@tailwind base;
@tailwind components;
@tailwind utilities;



.swiper-button-next:after,
.swiper-rtl .swiper-button-prev::after {
  content: "next";
  color: gray;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: gray;
  display: none;
  line-height: 100;
}

.swiper-pagination-bullet-active {
  background-color: gray;
}


.card {
  display: flex;
  perspective: 1000px;
}

.card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.999s;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card-front {
  background-color: #0a2061;
  color: #fff;
  display: flex;
  align-items: center;
 
  border-radius: 10px;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(0deg);
}

.card-back {
  background-color: #0d2d87;
  color: #fff;
  display: flex;
  align-items: center;
 
  border-radius: 10px;
  justify-content: center;
  font-size: 24px;
  transform: rotateY(180deg);
}
